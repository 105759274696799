.App {
    text-align: center;
}

.App-logo {
    height: 32px;
    pointer-events: none;
}

.App-header {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.App-header section {
    display: flex;
    padding: 31px 128px;
    justify-content: space-between;
    background: rgba(52, 54, 62, 0.8);
}

.App-header section div {
    display: flex;
    align-items: center;
}

.App-header section div.Page-links a   {
    padding: 0 20px;
    color: white;
    text-decoration: none;
}

.Get-app {
    color: #ffffff;
    background-color: #0066da;
    border-color: #0066da;
    text-transform: uppercase;
    border-radius: 30px;
    border-style: solid;
    border-top-width: 0;
    border-right-width: 0;
    border-left-width: 0;
    border-bottom-width: 0;
    padding: 16px 40px !important;
    font-family: inherit;
    font-weight: 700;
    font-size: 0.8em;
    text-decoration: none;
}

.App-header section:nth-child(2) {
    padding-bottom: 10vh;
}

.App-header section:nth-child(2) div:nth-child(1) {
    width: 50%;
    display: block;
    text-align: left;
    margin-bottom: 0;
    margin-top: 10vh;
}

.App-header section:nth-child(2) div:nth-child(1) h1 {
    font-size: 3em;
    color: white;
    font-weight: 700;
    margin: 0;
    line-height: 1.5;
}

.App-header section.Header-fill {
    margin: 0;
    display: block;
    padding: 0;
    transform: rotate(180deg);
    height: 40px;
    background: rgba(52, 54, 62, 0.8);
}

.App-header section.Header-fill-2 {
    display: block;
    padding: 0;
    height: 40px;
    background: white;
    margin: 0 0 20px;
}

section.Header-fill-2 svg, section.Header-fill svg {
    fill: #8FB3DC;
    height: 30px;
    width: 100%;
}

.Header-detail {
    font-size: 1.2em;
    color: white;
    font-weight: 400;
    margin: 0;
    line-height: 1.5;
}

section.header-fill {
    height: 0;
}

.header-shape-fill {
    fill: #8FB3DC;
}

.Sections {
    margin-top: 50px;
}

#features {
    color: black;
    text-align: start;
    padding: 0 128px;
}

#howitworks {
    background-color: rgba(27, 241, 174, 0.19);
    padding: 70px 128px 0;
}

#getapp {
    padding: 70px 128px;
}

#features h1, #howitworks h1 {
    font-size: 2.3em;
    font-weight: bold;
    margin: 0;
    line-height: 1.5;
    font-family: 'Poppins',sans-serif;
    text-align: left;
}

#getapp h1 {
    font-size: 2.3em;
    font-weight: bold;
    margin: 0;
    color: #0066DA;
    line-height: 1.5;
    font-family: 'Poppins',sans-serif;
    text-align: center;
}

.Features-icon {
    color: #0066DA;
    fill: #0066DA;
    border-color: #0066DA;
    display: inline-block;
    line-height: 1;
    transition: all .3s;
    font-size: 10px;
    text-align: center;
    width: 40px !important;
    margin-bottom: 10px;
}

.Feature {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
}

.Feature div.Image-placeholder {
    width: 40%;
    text-align: left;
}

.Feature div.Feature-Description {
    width: 50%;
    text-align: left;
}

.Feature h3 {
    font-size: 1.4em;
    font-weight: 700;
    line-height: 1.2em;
    font-family: 'Poppins',sans-serif;
    margin: 0 0 10px;
}

.Feature p {
    font-size: 1em;
    font-weight: 400;
    margin: 0;
    line-height: 1.5em;
    font-family: 'Poppins',sans-serif;
}

.Image-placeholder img{
    width: 100%;
    height: auto;
}

.elementor-icon-list-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px !important;
}

.elementor-icon-list-text {
    font-size: 1.5em;
}

.elementor-icon-list-icon svg {
    fill: #00AC47;
    transition: fill 0.3s;
}

.Image-placeholder.right {
    display: none !important;
}

#getapp .about {
    font-size: 25px;
    font-weight: 400;
    line-height: 1.5;
    font-family: 'Poppins',sans-serif;
    text-align: center;
}

#getapp .links {
    margin-top: 30px;
}

#getapp .links img {
    width: auto;
    height: 70px;
    margin: 0 10px;
}

#colophon {
    background-color: #10182D;
    background-image: none;
    color: #ffffff;
    fill: white;
    padding: 70px 128px;
}

.footer-social-inner-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-social-inner-wrap svg {
    fill: white;
    height: 18px;
    width: 18px;
    margin: 0 10px;
}

#colophon a {
    color: #00ac47;
    text-decoration: none;
}

.ast-footer-copyright {
    margin-top: 40px;
}

@media (max-width: 970px) {
    .App-header section {
        padding: 31px 20px;
    }

    .App-header section div {
        display: block;
    }

    .App-header section div.Page-links {
        display: none;
    }

    .App-header section:nth-child(2) div:nth-child(1) {
        width: 100%;
        margin-top: 0;
    }

    .App-header section:nth-child(2) div:nth-child(1) h1 {
        font-size: 2em;
    }

    #features {
        padding: 0 20px;
    }

    #howitworks {
        padding: 70px 20px 0;
    }

    #getapp {
        padding: 70px 20px;
    }

    .Feature {
        display: block;
        margin-bottom: 50px;
    }

    .Feature div.Image-placeholder {
        width: 100%;
        text-align: center;
    }

    .Feature div.Feature-Description {
        width: 100%;
        text-align: center;
    }

    .elementor-icon-list-item {
        display: block;
    }

    .elementor-icon-list-text {
        font-size: 1.2em;
    }

    .elementor-icon-list-icon svg {
        fill: #00AC47;
        transition: fill 0.3s;
    }

    .Image-placeholder.left {
        display: none !important;
    }

    .Image-placeholder.right {
        display: block !important;
    }

    #colophon {
        padding: 70px 20px;
    }

    .footer-social-inner-wrap {
        display: block;
    }

    .footer-social-inner-wrap svg {
        fill: white;
        height: 18px;
        width: 18px;
        margin: 0 10px;
    }

    .App-header section div.Page-links a {
        padding: 0 10px;
    }

    .Get-app {
        padding: 16px 20px !important;
    }

    .App-header section:nth-child(2) {
        padding-bottom: 10vh;
    }

    #getapp .links img {
        width: 50%;
        height: auto;
        margin: 0 10px;
    }
}